import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngGameLunchpadePage = loadable(() => import("../components/GameLunchpadePage/english.js"));
const ArabicGameLunchpade = loadable(() => import("../components/GameLunchpadePage/arabic.js"));
const ChineseGameLunchpade = loadable(() => import("../components/GameLunchpadePage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-7.webp",
            "name": "Initial Game Offering Launchpad Development Company",
            "description": "Softtik is the leading Initial Game Offering (IGO) launchpad development company offering feature-rich development solutions with the latest technology.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "104"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "Will you create a Native Token for the IGO Launchpad?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Yes, we are pioneers in providing blockchain-based services and solutions to our clients. Contact us today with the set of requirements and get your work done.</p>"
                }
            }, {
                "@type": "Question",
                "name": "What are other types of fundraising platform you can build?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Along with the Initial Game Offering (IGO), we are experts in developing launchpads for ICO (Initial Coin Offering), INO (Initial NFT Offering), IDO (Initial DEX Offering), and IEO (Initial Exchange Offering).</p>"
                }
            }, {
                "@type": "Question",
                "name": "What is the cost of the development of the IGO platform?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The cost of the development of the IGO platform depends on your requirements. Get in touch with us and share your requirements to know the cost of <b>IGO launchpad development</b>.</p>"
                }
            }
            ]
        }
    ]
}

export class GameLunchpade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/igo-launchpad-development-services/"
                        title="Initial Game Offering Launchpad Development Company - Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-7.webp"
                        description="Softtik is the leading Initial Game Offering (IGO) launchpad development company offering feature-rich development solutions with the latest technology."
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicGameLunchpade />
                                    : lang == "China"
                                        ? <ChineseGameLunchpade />
                                        : <EngGameLunchpadePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default GameLunchpade;